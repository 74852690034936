let logo_sliders = document.querySelectorAll('.logo-slider--slider');

logo_sliders.forEach(slider => {
    tns({
        container: slider,
        items: 2,
        slideBy: 1,
        autoplay: false,
        nav: false,
        controlsText: ['<i class="customicon-chevron-left"></i>', '<i class="customicon-chevron-right"></i>'],
        navPosition: "bottom",
        loop: false,
        edgePadding: 20,
        gutter: 20,
        responsive: {
            768: {
                items: 4
            },
            1024: {
                items: 5
            }
        }
    });
});
